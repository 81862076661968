import { useState } from 'react';
import { Grid, MenuItem, TextField, Typography } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import { gridSpacing } from 'store/constants/constant';

const CustomBarChart = ({ isLoading, title, total, data, dataKeys, statusOptions, barSize }) => {
    const [value, setValue] = useState(statusOptions[0]?.value);

    return (
        <div style={{ backgroundColor: 'white', padding: 20 }}>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <Typography variant="subtitle1">{title}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h3">{total}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid item>
              <TextField
                select
                value={value}
                onChange={(e) => setValue(e.target.value)}
              >
                {statusOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid> */}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <ResponsiveContainer width="100%" height={480}>
                        <BarChart
                            data={data}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5
                            }}
                        >
                            <CartesianGrid strokeDasharray="1" stroke="#f5f5f5" />
                            <XAxis dataKey="name" tick={{ fill: '#b2b9bf' }} />
                            <YAxis tick={{ fill: '#b2b9bf' }} />
                            <Tooltip />
                            <Legend />
                            {dataKeys.map((key, index) => (
                                <Bar key={key.name} dataKey={key.name} stackId="a" fill={key.color} barSize={barSize}>
                                    <LabelList dataKey={key.name} position="inside" fill="#fff" />
                                </Bar>
                            ))}
                        </BarChart>
                    </ResponsiveContainer>
                </Grid>
            </Grid>
        </div>
    );
};

export default CustomBarChart;
